import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../Media/logo.png";
import Footer from "../Footer/Footer";
import "./FooterContent.css";
import "aos/dist/aos.css";
import Aos from "aos";

const TermsCondition = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <Navbar className="footer_navbar">
        <Navbar.Brand style={{ margin: "0 0 0 0" }}>
          <img src={logo} onClick={() => history.push("/")} alt="logo" fluid />
        </Navbar.Brand>
      </Navbar>
      <div className="about_us user-select-none">
        <h3 data-aos="fade-up">Terms and Condition</h3>
        <p data-aos="fade-up">
          THE FOLLOWING TERMS AND CONDITIONS (“T&C”) GOVERN YOUR ACCESS TO AND
          USE OF ONLINE DOCTOR PORTAL WEBSITE’s PATHOLOGY SERVICES ). THESE
          TERMS AND CONDITIONS CREATE A CONTRACT BETWEEN YOU AND Care-Box
          LIMITED. IF YOU ARE UNDER THE AGE OF 18, YOU HEREBY CONFIRM THAT YOU
          HAVE YOUR PARENTS’ OR LEGAL GUARDIAN’S PERMISSION TO USE THE PLATFORM.
          PLEASE READ THESE T&C CAREFULLY BEFORE YOU START USING THIS PLATFORM.
          BY CLICKING “AGREE/ACCEPT” YOU AGREE TO BE BOUND BY THESE T&C AT ALL
          TIMES. NON-ACCEPTANCE OF THE T&CS SHALL DISALLOW YOU TO USE THE
          PLATFORM.
        </p>
        <br />
        <h4 data-aos="fade-up">Pathology Services:</h4>
        <p data-aos="fade-up" style={{ paddingLeft: "10px" }}>
          <p data-aos="fade-up" id="footP">
            1. To avail our pathology services, a customer needs to register
            with us at first.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            2. In creating an account by registration and access the Website,
            you represent that you are over the age of 18 and not barred from
            using the Website under applicable law. If you are a minor, you
            hereby confirm that you have your parent or legal guardian reviewed
            and accepted these T&C and granted you the permission to use the
            Website.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            3. You represent and warrant that all information (full name,
            gender, birth date, active mobile number, email address) and profile
            picture provided by you to register with the Website are accurate,
            updated and shall remain valid at all time.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            4. Attempts to breach security of the Website on any computer or
            mobile network or access an account that does not belong to you on
            theWebsite is strictly prohibited.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            5. To prevent fraud or abuse, only one registered account per device
            is allowed. Any attempt to create additional accounts shall be
            invalid.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            6. A customer will need to pay beforehand. A customer cannot book a
            pathology test without paying.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            7. A patient can only pay through online payment. Here, no Cash on
            Delivery is applicable.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            8. Report Delivery will be within 24 hours, after the test results
            are published by the diagnostic centre.
          </p>
          <br />
          <br />
          <p data-aos="fade-up">
            9. All the reports will be uploaded to our backend system for any
            future consultations. Or if in any case, the customer loses the hard
            copies then he/she can contact us for another copy.
          </p>
        </p>
        <br />
        <h4 data-aos="fade-up">Intellectual property rights:</h4>
        <p data-aos="fade-up" style={{ paddingLeft: "10px" }}>
          <p data-aos="fade-up" id="footP">
            Online Doctor portal website and all of its contents, features, and
            functionality (including but not limited to all information,
            software, text, displays, images, video, and audio and the design,
            selection and arrangement thereof) are owned by Care-Box Ltd., its
            licensors, and other providers of such material. You must not:
          </p>{" "}
          <br />
          <br />{" "}
          <p data-aos="fade-up" id="footP">
            a) modify copies of any materials from the website;
          </p>{" "}
          <br />
          <br />{" "}
          <p data-aos="fade-up" id="footP">
            b) use any illustration, photographs, video or audio sequences or
            any graphics separately from the accompanying text;
          </p>{" "}
          <br />
          <br />{" "}
          <p data-aos="fade-up" id="footP">
            c) delete or alter any copyright, trademark, or other proprietary
            rights notices from copies of materials from the Platform; and
          </p>{" "}
          <br />
          <br />{" "}
          <p data-aos="fade-up">
            d) reverse-engineer or otherwise attempt to steal the
            software/application code of the website. Any use of the website not
            expressly permitted by the T&C is a breach of these T&C and may
            violate copyright, trademark, and other laws and you will be liable
            to loss, damages and indemnify and hold harmless Care-Box Ltd and
            any other third party for such breach of copyright, trademark or
            other intellectual property rights.
          </p>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsCondition;
