import moment from "moment";
import React, { useEffect, useState } from "react";
import "../Dashboard/dashboard.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getUserDetails } from "../../Redux/Actions/userActions";
import { jsPDF } from "jspdf";
import ReviewModal from "../ReviewModal/ReviewModal";

const Dashboard = () => {
  const [myTest, setMyTest] = useState([]);

  const [presc, setPresc] = useState();

  const [reviewDiagnosticId, setReviewDiagnosticId] = useState();
  //console.log("🚀reviewDiagnosticId", reviewDiagnosticId);

  useEffect(() => {
    fetch("https://www.api-care-box.click/api/v2/pathology/my_tests/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setMyTest(data);
      });
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const locationset = useLocation();
  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;

  useEffect(() => {
    if (error) {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  const [reviewModalIsOpen, setReviewIsOpen] = useState(false);

  function openReviewModal() {
    setReviewIsOpen(true);
  }

  function closeReviewModal() {
    setReviewIsOpen(false);
  }

  const handleReportDownload = (test) => {
    openReviewModal();
    setReviewDiagnosticId(test.ordered_medical_test[0].diagnostic_center_id);

    let data = myTest.filter((item) => item.id === test.id)[0];
    let pres = data.Report;
    setPresc(pres);
  };

  return (
    <section style={{ overflow: "hidden" }}>
      <Header></Header>
      <div className="container dashboard mt-md-4">
        <p>
          Pathology <span style={{ color: "#BD0451" }}>Dashboard</span>
        </p>
      </div>

      <ReviewModal
        modalIsOpen={reviewModalIsOpen}
        closeModal={closeReviewModal}
        reviewDiagnosticId={reviewDiagnosticId}
      ></ReviewModal>

      <div className="container mb-5 mt-md-4 dashboard overflow-auto">
        <table className="table table-hover text-center shadow-sm">
          <thead className="shadow">
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Patient Name</th>
              <th scope="col">Tests Booked</th>
              <th scope="col">Sample Collection Status</th>
              <th scope="col">Date & Time</th>
              <th scope="col">Report Download</th>
            </tr>
          </thead>
          <tbody>
            {myTest.map((item , i) => (
              <motion.tr initial={{ opacity: 0 }} animate={{ opacity: 1 }} key={i}>
                <th scope="row">{item.id}</th>
                <td>{item.Patient_Name}</td>
                <td>
                  <div className="dropdown">
                    <button
                      className="dropdown-toggle btn-sm viewDetails_button"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      View Details
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {item.ordered_medical_test.map((item, index) => (
                        <div key={index}>
                          <p
                            className="dropdown-item m-0"
                            style={{ fontSize: "17px" }}
                          >
                            {/* {index+1} {" "} */}
                            {item.Test_Name}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </td>
                <td>
                  {item.Sample_Collection_Status === "Pending" ? (
                    <div className="row justify-content-center align-items-center">
                      <div
                        className="col-md-1 shadow"
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "gray",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div className="col-md-6">Pending</div>
                    </div>
                  ) : item.Sample_Collection_Status === "Confirmed" ? (
                    <div className="row justify-content-center align-items-center">
                      <div
                        className="col-md-1 shadow"
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#5a4fcf",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div className="col-md-6">Confirmed</div>
                    </div>
                  ) : item.Sample_Collection_Status === "Canceled" ? (
                    <div className="row justify-content-center align-items-center">
                      <div
                        className="col-md-1 shadow"
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#A11616",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div className="col-md-6">Canceled</div>
                    </div>
                  ) : item.Sample_Collection_Status === "On The Way" ? (
                    <div className="row justify-content-center  align-items-center">
                      <div
                        className="col-md-1 shadow"
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#87CEFA",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div className="col-md-6">On The Way</div>
                    </div>
                  ) : item.Sample_Collection_Status === "Stuck in Traffic" ? (
                    <div className="row justify-content-center  align-items-center">
                      <div
                        className="col-md-1 shadow"
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#f7b500",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div className="col-md-6">Stuck in Traffic</div>
                    </div>
                  ) : (
                    <div className="row justify-content-center  align-items-center">
                      <div
                        className="col-md-1 shadow"
                        style={{
                          width: "25px",
                          height: "25px",
                          backgroundColor: "#51A120",
                          borderRadius: "50%",
                        }}
                      ></div>
                      <div className="col-md-6">Collected</div>
                    </div>
                  )}
                </td>
                <td>{moment(item.createdAt).format("Do MMM YY, h:mm a")}</td>
                <td>
                  {item.Report_Status !== "Under Processing" ? (
                    // <button
                    //   className="report_download"
                    //   onClick={() => handleReportDownload(item)}
                    // >
                    //   Download
                    // </button>
                    <a
                      className="report_download"
                      onClick={() => handleReportDownload(item)}
                      href={presc}
                      download
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  ) : (
                    <button className="btn btn-secondary" onClick={() => alert("Currently , Report is not Available")}>Download</button>
                  )}
                </td>
              </motion.tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default Dashboard;
