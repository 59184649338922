import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import logo from "../../Media/logo.png";
import check from "../../Media/checked.png";
import success from "../../Media/succ-01.png";
import "../Payments/payment.css";
import Footer from "../Footer/Footer";

const PaymentSuccess = () => {
  const history = useHistory();

  useEffect(() => {
    let interval = null;
    interval = setInterval(function () {
      localStorage.removeItem("ssl_book");
      history.push("/dashboard");
    }, 3000);
    return () => (interval ? clearInterval(interval) : null);
  }, []);
  return (
    <section>
      <div className="container">
        <div className="text-center p-4">
          <img className="img-fluid" src={logo} alt="..." />
        </div>

        <div className="row justify-content-between align-items-center pt-5 pb-5 mt-2 mb-2">
          <div className="col-md-6 text-center pr-3 pr-md-5 payment_border">
            <h1 style={{ color: "#bd095a" }}>Congratulations!</h1>
            <img className="img-fluid" src={success} alt="success_logo" />
          </div>
          <div className="col-md-6 text-center pl-3 pl-md-5">
            <img
              className="img-fluid"
              style={{ width: "20%" }}
              src={check}
              alt="check sign"
            />
            <h5 className="mt-3" style={{ color: "#bd095a" }}>
              Your booking has been successfully placed. Redirecting to
              dashboard. . .
            </h5>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default PaymentSuccess;
