import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import PricingOptionDetails from "./PricingOptionDetails";
import ssl from "../../Media/ssl.png";
import "../PricingOptions/pricingOptions.css";
import Swal from "sweetalert2";
import { CART_CLEAR_ITEMS } from "../../Redux/Type";
import { getUserDetails } from "../../Redux/Actions/userActions";
import { useLocation } from "react-router-dom";
import MissingTestModal from "../MissingTestModal/MissingTestModal";

const PricingOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const selectedTest = useSelector((state) => state.cart);
  const { cartItems } = selectedTest;

  const locationset = useLocation();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  useEffect(() => {
    if (error) {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  const [diagnostic, setDiagnostic] = useState([]);
  // console.log(diagnostic);

  const [selectedItem, setSelectedItem] = useState(null);
  //console.log("price", selectedItem);

  const [missingModalIsOpen, setMissingIsOpen] = useState(false);

  function openMissingModal() {
    setMissingIsOpen(true);
  }

  function closeMissingModal() {
    setMissingIsOpen(false);
  }

  //console.log("missingModalIsOpen", missingModalIsOpen);

  const missingTest = selectedItem?.Missing_test || [];
  //console.log("selectedItem", selectedItem);

  useEffect(() => {
    if (missingTest.length > 0) {
      openMissingModal();
    }
  }, [missingTest]);

  useEffect(() => {
    fetch(
      "https://www.api-care-box.click/api/v2/pathology/diagnostic_center_list/",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (cartItems.length > 0) {
          setDiagnostic(data);
        }
      });
  }, []);

  const handleBooking = () => {
    if (localStorage.getItem("patientForm")) {
      const patientFormData = JSON.parse(localStorage.getItem("patientForm"));
      var name = patientFormData.name;
      var phone = patientFormData.phone.phone;
      var gender = patientFormData.gender;
      var age = patientFormData.age;
      var note = patientFormData.note;
    } else {
      history.push("/patientform");
    }

    if (localStorage.getItem("address")) {
      const patientAddress = JSON.parse(localStorage.getItem("address"));
      var area = patientAddress.area;
      var house = patientAddress.house;
      var flat = patientAddress.flat;
    } else {
      history.push("/map");
    }

    // const abc = patientTestItems.map((item) => ({
    //   DiagnosticTest: item.test,
    // }));

    const abc = selectedItem?.DiagnosticTest_id?.map((item) => item);

    if (!abc || abc === []) {
      alert("Please Select a diagonistic center");
    }
    // const bcd = selectedItem;
    // console.log("selectedItem", bcd);

    // const def = bcd?.DiagnosticTest_id;
    // console.log("diag test id", def);

    // {
    //   // const cd = JSON.stringify(abc);
    //   // console.log("!@!@@", cd);
    //   // const formData = new FormData();
    //   // formData.append("User", 230);
    //   // formData.append("Patient_Name", name);
    //   // formData.append("Phone", phone);
    //   // formData.append("Gender", gender);
    //   // formData.append("Age", age);
    //   // formData.append("SpecialNote", note);
    //   // formData.append("Area", area);
    //   // formData.append("House", house);
    //   // formData.append("Flat", flat);
    //   // formData.append("Total_Price", selectedItem.Total_Price);
    //   // formData.append("Is_Posted_From", "Website");
    //   // Array.from(abc).forEach((tag) => console.log(tag));
    //   // Array.from(cd).forEach((tag) =>
    //   //   formData.append("ordered_medical_test", (tag))
    //   // );
    //   // abc.forEach(element => {
    //   //   formData.append("ordered_medical_test", JSON.stringify(element))
    //   // });
    //   // Array.prototype.forEach.call(abc, (tag) => {
    //   //   console.log(tag);
    //   //   formData.append("ordered_medical_test", JSON.stringify(tag))
    //   // });
    //   // Array.prototype.forEach.call(patientTestItems, (tag) => {
    //   //   formData.append("ordered_medical_test", JSON.stringify(tag));
    //   // });
    //   // formData.append("ordered_medical_test", cd);
    //   // patientTestItems.forEach((element) => {
    //   //   formData.append("ordered_medical_test", { DiagnosticTest: element.test });
    //   // });
    //   // formData.append(
    //   //   "ordered_medical_test",
    //   //   patientTestItems.map((element) => {
    //   //     const x = {
    //   //       DiagnosticTest: element.test
    //   //     }
    //   //     const y = JSON.stringify(x);
    //   //     return y;
    //   //   })
    //   // );
    //   // patientTestItems.map((element) => {
    //   //   const x = {
    //   //     DiagnosticTest: element.test,
    //   //   };
    //   //   const y = JSON.stringify(x);
    //   //   return formData.append("ordered_medical_test", y);
    //   // });
    //   // const barun = {
    //   //   DiagnosticTest: patientTestItems[0].test,
    //   // };
    //   // const zihad = JSON.stringify(barun);
    //   // console.log(zihad);
    //   // formData.append("ordered_medical_test", zihad);
    //   // formData.append("ordered_medical_test", localStorage.getItem("cartItems"));
    //   // console.log(formData.getAll("User"));
    //   // console.log(formData.getAll("Patient_Name"));
    //   // console.log(formData.getAll("Phone"));
    //   // console.log(formData.getAll("Gender"));
    //   // console.log(formData.getAll("Age"));
    //   // console.log(formData.getAll("SpecialNote"));
    //   // console.log(formData.getAll("Area"));
    //   // console.log(formData.getAll("House"));
    //   // console.log(formData.getAll("Flat"));
    //   // console.log(formData.getAll("Total_Price"));
    //   // console.log(formData.getAll("ordered_medical_test"));
    //   // const data2 = {
    //   //   User: 230,
    //   //   Patient_Name: "Zihad",
    //   //   Phone: "+8801752746973",
    //   //   Gender: "Male",
    //   //   Age: 21,
    //   //   SpecialNote: "Testing",
    //   //   Area: "basundhara",
    //   //   House: "31",
    //   //   Flat: "6A",
    //   //   Total_Price: "0.00",
    //   //   Is_Posted_From: "Website",
    //   //   ordered_medical_test: [
    //   //     {
    //   //       DiagnosticTest: 1,
    //   //     },
    //   //     {
    //   //       DiagnosticTest: 3,
    //   //     },
    //   //   ],
    //   // };
    // }
    const bookDiagnosticTest = {
      User: user.id,
      Patient_Name: name,
      Phone: phone,
      Gender: gender,
      Age: age,
      SpecialNote: note,
      Area: area,
      House: house,
      Flat: flat,
      Service_Charge: selectedItem?.Service_Charge,
      Total_Price: selectedItem.Total_Price,
      Is_Posted_From: "Website",
      ordered_medical_test: abc,
    };

    if (
      name !== "" &&
      phone !== "" &&
      gender !== "" &&
      area !== "" &&
      house !== "" &&
      flat !== "" &&
      abc.length > 0
    ) {
      fetch(
        `https://www.api-care-box.click/api/v2/pathology/book_diagnostic_test/`,
        {
          method: "POST",
          // mode:"no-cors",
          body: JSON.stringify(bookDiagnosticTest),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            // Accept: "application/json",
            // Accept: "application/json, text/plain",
            // "Content-Type": "application/json; text/html; charset=UTF-8",
            // media_type: "multipart/form-data",
            // "Content-Type": "multipart/form-data",
            // "Access-Control-Allow-Origin": "*",
            // "Content-Type": "application/x-www-form-urlencoded",
            // 'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.message === "You have successfully booked your test.") {
            // history.push("/dashboard");
            localStorage.setItem("ssl_book", "patient_applied");
            history.push("/paymentloading");
            dispatch({
              type: CART_CLEAR_ITEMS,
              payload: data,
            });
            localStorage.removeItem("cartItems");
            localStorage.removeItem("address");
            localStorage.removeItem("patientForm");
          }
        })
        .catch((err) => {
          //console.log("errorrrrrrr", err);
        });
    } else {
      Swal.fire({
        position: "top",
        icon: "error",
        title: "Give Credentials Properly",
        text: "Give Your Information Correctly. . .",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const handleDisableBtn = () => {
    Swal.fire({
      position: "top",
      icon: "info",
      title: "Select Diagnostic",
      text: "You have to select a diagnostic first",
      showConfirmButton: false,
      // timer: 1500,
    });
  };

  return (
    <section>
      <MissingTestModal
        modalIsOpen={missingModalIsOpen}
        closeModal={closeMissingModal}
        missingTest={missingTest}
      ></MissingTestModal>
      <Header></Header>
      <div className="container mb-5 mt-md-4">
        <div className="row">
          <div className="col-md-7">
            <p className="page_title">
              Select A{" "}
              <span style={{ color: "#BD0451" }}>Diagnostic Center</span>
            </p>
            {diagnostic.length ? (
              <div className="row">
                {diagnostic.map((selectedItem) => {
                  return (
                    <PricingOptionDetails
                      selectedItem={selectedItem}
                      setSelectedItem={setSelectedItem}
                    ></PricingOptionDetails>
                  );
                })}
              </div>
            ) : (
              <div className="h-100 d-flex justify-content-center add_more p-4">
                <button
                  onClick={() => history.push("/availabletests")}
                  className="align-self-center"
                >
                  Please Add a Test to Continue
                </button>
              </div>
            )}
          </div>
          <div className="col-md-5 add_more">
            <p className="page_title">
              Pricing <span style={{ color: "#BD0451" }}>Options</span>
            </p>
            <div className="card shadow-lg border-0">
              <div className="card-body pricing_card_body">
                <p className="card-title">
                  <span style={{ color: "#BD0451" }}>Booking</span> Payment
                  Summary
                </p>

                {selectedItem ? (
                  <div className="card-text">
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Subtotal
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        {/* {selectedItem?.Sub_Total} ৳ */}
                        {selectedItem?.Sub_Total ? (
                          <>{selectedItem?.Sub_Total} ৳</>
                        ) : (
                          <div
                            class="spinner-border text-secondary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Disc Price({selectedItem?.Discount}%)
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        {/* {selectedItem?.Sub_Total_After_Discount} ৳ */}
                        {selectedItem?.Sub_Total_After_Discount ? (
                          <>
                            {selectedItem?.Sub_Total -
                              selectedItem?.Sub_Total_After_Discount}{" "}
                            ৳
                          </>
                        ) : (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Collection Fee
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        {/* {selectedItem?.Collection_Fee} ৳ */}
                        {selectedItem?.Collection_Fee ? (
                          <>{selectedItem?.Collection_Fee} ৳</>
                        ) : (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Charge
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        {/* {selectedItem?.Service_Charge} ৳ */}
                        {selectedItem?.Service_Charge ? (
                          <>{selectedItem?.Service_Charge} ৳</>
                        ) : (
                          <div
                            className="spinner-border text-secondary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="card-text">
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Subtotal
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        0 ৳
                      </p>
                    </div>
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Disc Price (0%)
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        0 ৳
                      </p>
                    </div>
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Collection Fee
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        0 ৳
                      </p>
                    </div>
                    <div className="charges d-flex justify-content-center align-items-center">
                      <p className="m-0" style={{ width: "114px" }}>
                        Charge
                      </p>
                      <hr className="hrPrice" />
                      <p
                        className="m-0 p-1"
                        style={{ width: "65px", textAlign: "right" }}
                      >
                        0 ৳
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <p className="text-center m-0 p-2" style={{ color: "#707070" }}>
                Available Payment Methods
              </p>
              <img
                style={{
                  width: "70%",
                  margin: "0 auto",
                }}
                src={ssl}
                class="img-fluid"
                alt="..."
              />

              <div className="d-flex justify-content-between align-items-center">
                <p className="m-0 pl-4">Subtotal:</p>
                {selectedItem ? (
                  <p className="m-0 pr-4" style={{ textAlign: "right" }}>
                    {/* {selectedItem?.Total_Price} ৳ */}
                    {selectedItem?.Total_Price ? (
                      <>{selectedItem?.Total_Price} ৳</>
                    ) : (
                      <div
                        className="spinner-border text-secondary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </p>
                ) : (
                  <p className="m-0 pr-4">0 ৳</p>
                )}
              </div>

              {selectedItem ? (
                <button className="m-3" onClick={handleBooking}>
                  CONFIRM BOOKING
                </button>
              ) : (
                <button
                  className="m-3"
                  onClick={handleDisableBtn}
                  style={{
                    backgroundColor: "gray",
                    border: "1px solid gray",
                    cursor: "context-menu",
                  }}
                >
                  CONFIRM BOOKING
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default PricingOptions;
