import React, { useEffect, useState } from "react";
import img from "../../Media/pathology.png";
import selected from "../../Media/selected.png";
import add from "../../Media/add.png";
import ibnSina from "../../Media/ibn-sina.png";
import "../AvailableTests/availableTests.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../Redux/Actions/CartAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getByTestId } from "@testing-library/react";
import { motion } from "framer-motion";

const AvailableTestDetails = (props) => {
  const { availableCard } = props;

  const [qty, setQty] = useState(1);
  const [counter, setCounter] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  let availableCardId = availableCard.id;
  useEffect(() => {
    if (counter === 2) dispatch(addToCart(availableCardId, qty));
    setCounter(1);
  }, [counter]);

  const addToCartHandler = () => {
    setCounter(2);
    setTimeout(() => {
      history.push("/selectedtests");
    }, 500);
  };

  const selectedTest = useSelector((state) => state.cart);
  const { cartItems } = selectedTest;
  // console.log("selected Test in available test", cartItems);

  const isItemExist = (id) => {
    return cartItems.findIndex((item) => item.test === id) === -1;
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="col-lg-4 col-sm-6 mb-3"
    >
      <div
        onClick={addToCartHandler}
        className="card h-100 justify-content-start align-items-start justify-content-md-center align-items-md-center shadow-lg border-0 availableTest_card_container"
      >
        <div className="availableTest_card_corner">
          {isItemExist(availableCard.id) ? (
            <>
              <div className="availableTest_card_corner_addText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <img className="img-fluid corner_img" src={add} alt="add" />
            </>
          ) : (
            <>
              <div className="availableTest_card_corner_addText">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <img className="img-fluid corner_img" src={selected} alt=".." />
            </>
          )}
        </div>
        <div className="row justify-content-start align-items-start justify-content-md-center align-items-md-center">
          <div className="col-md-4">
            <img
              className="img-fluid pl-0 pl-md-2 p-0 p-md-0 p-sm-5 availableTest_card_img"
              src={availableCard.image}
              alt="..."
            />
          </div>
          <div className="col-md-8 mt-4">
            <div className="card-body m-0 p-0 pl-md-0 pl-sm-2 availableTest_card_body">
              <h5 className="card-title" style={{ color: "#BD0451" }}>
                {availableCard.TestName}
              </h5>
              <p className="card-text pr-2 pb-2">{availableCard.Details}</p>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
{
  /**
   * <div className="test-card-details-corner">
              <div className="test-card-details-corner-addText">
                Details
              </div>
            </div>
               *<div className="card-footer">
          {availableCard.AvailableDiagnosticCenter?.map((center, i) => {
            return (
              <div key={i}>
                {center.Diagnostic_Center_Name === "Ibn Sina" ? (
                  <img src={ibnSina} alt="..." height={40} />
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
               */
}
export default AvailableTestDetails;
