import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../PricingOptions/pricingOptions.css";
import { motion } from "framer-motion";
import ReactReadMoreReadLess from "react-read-more-read-less";
import MissingTestModal from "../MissingTestModal/MissingTestModal";

const PricingOptionDetails = (props) => {
  const { selectedItem, setSelectedItem } = props;

  const [diagnosticId, setDiagnosticId] = useState(null);
  const [priceData, setPriceData] = useState(null);

  const [missingModalIsOpen, setMissingIsOpen] = useState(false);

  function openMissingModal() {
    setMissingIsOpen(true);
  }

  function closeMissingModal() {
    setMissingIsOpen(false);
  }

  // console.log("price data", priceData);

  const missingTest = priceData?.Missing_test || [];
  // console.log("Missing Test###", missingTest.length);

  useEffect(() => {
    if (missingTest.length > 0) {
      openMissingModal();
    }
  }, [missingTest]);

  const selectedTest = useSelector((state) => state.cart);
  const { cartItems } = selectedTest;
  const cartTestId = cartItems.map((testId) => testId.test);
  const cartTestAllId = cartTestId.join(" ");

  const handleChange = () => {
    var rad = document.getElementsByClassName("input_radio");
    var prev = null;
    for (var i = 0; i < rad.length; i++) {
      rad[i].addEventListener("change", function () {
        if (this !== prev) {
          prev = this;
        }
        setDiagnosticId(this.value);
      });
    }
  };

  const url = `https://www.api-care-box.click/api/v2/pathology/view_medical_test_pricing/?test_id=${cartTestAllId}&diagnostic_center_id=${diagnosticId}`;
  // const url = `https://www.api-care-box.click/api/v2/pathology/view_medical_test_pricing/?test_id=4 5 6 7 3 1 2 &diagnostic_center_id=${diagnosticId}`;
  // console.log(url);
  useEffect(() => {
    if (diagnosticId !== null) {
      fetch(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setPriceData(data);
          setSelectedItem(data);
          // console.log("pricing option", data);
        });
    }
  }, [diagnosticId]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="col-md-12 mb-3"
    >
      {/* <MissingTestModal
        modalIsOpen={missingModalIsOpen}
        closeModal={closeMissingModal}
        missingTest={missingTest}
      ></MissingTestModal> */}
      <div className="card h-100 justify-content-start align-items-start justify-content-md-center align-items-md-center shadow-lg border-0 selectedTest_card_container">
        <div className="row justify-content-start align-items-start justify-content-md-center align-items-md-center">
          <div className="col-md-3 text-center">
            <img
              className="img-fluid pl-0 pl-md-2 pl-lg-4 pl-sm-5 pr-0 pr-md-0 pr-lg-0 pr-sm-5 pt-0 pt-md-0 pt-lg-4 pt-sm-5 pb-0 pb-md-0 pb-lg-4 pricing_card_img"
              src={selectedItem.Image}
              alt="..."
            />
          </div>
          <div className="col-md-6 mt-4 p-md-0 order-2 order-md-1">
            <div className="card-body m-0 p-0 pl-md-0 pl-sm-2 selectedTest_card_body">
              <h5 className="card-title" style={{ color: "#BD0451" }}>
                {selectedItem.Diagnostic_Center_Name}
              </h5>
              <p className="card-text pr-2 pb-2 text-justify">
                <ReactReadMoreReadLess
                  charLimit={80}
                  readMoreText={"Read more ▼"}
                  readLessText={"Read less ▲"}
                  readMoreClassName="read-more-less--more"
                  readLessClassName="read-more-less--less"
                >
                  {selectedItem.Description}
                </ReactReadMoreReadLess>
              </p>
            </div>
          </div>
          <div className="col-md-3 order-1 order-md-2">
            {/**<label className="form-check-label select-label" for="exampleRadios1">
              Select
            </label> */}
            <input
              className="input_radio"
              type="radio"
              name="exampleRadios"
              onChange={handleChange}
              value={selectedItem.id}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PricingOptionDetails;
