import React, { useState } from "react";
import add from "../../Media/add.png";
import selected from "../../Media/selected.png";
import "../SelectedTests/selectedTests.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { removeFromCart } from "../../Redux/Actions/CartAction";
import { motion } from "framer-motion";
//import lalpathlabs from "../../Media/dr-lal-01.png";
import TestDetailsModal from "./TestDetailsModal";

const SelectedTestDetails = (props) => {
  const { selectedItem } = props;
  // console.log("selectedItem", selectedItem);

  const dispatch = useDispatch();

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };
  const [testName, setTestName] = useState();
  const [popularTestData, setPopularTestData] = useState();
  const [ibnSinaTestData, setIbnSinaTestData] = useState();
  const [unitedTestData, setUnitedTestData] = useState();
  const [lalPathlabsTestData, setLalPathLabsTestData] = useState();
  const [pravehealthTestData, setLalPravaHealthTestData] = useState();

  const fetchTestDetails = async (testId, diagnosticId) => {
    await fetch(
      `https://www.api-care-box.click/api/v2/pathology/view_medical_test_pricing/?test_id=${testId}&diagnostic_center_id=${diagnosticId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        //setPriceData(data);
        //setSelectedItem(data);
        // if (diagnosticId == 1) {
        //   setPopularTestData(data);
        // } else if (diagnosticId == 2) {
        //   setIbnSinaTestData(data);
        // } else if (diagnosticId == 4) {
        //   setUnitedTestData(data);
        // } else if (diagnosticId == 5) {
        //   setLalPathLabsTestData(data);
        // } else
        if (diagnosticId == 10) {
          setLalPravaHealthTestData(data);
        } else {
          //console.log("pricing option", data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  //console.log(show);
  const handleTestModalData = (testId, testName) => {
    setShow(true);
    //console.log(show);
    setTestName(testName);
    //fetchTestDetails(testId, 1);
    //fetchTestDetails(testId, 2);
    //fetchTestDetails(testId, 4);
    //fetchTestDetails(testId, 5);
    fetchTestDetails(testId, 10);
  };
  //console.log("lal",lalPathlabsTestData?.Missing_test.length);
  //console.log("ibn",ibnSinaTestData?.Missing_test.length);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="col-md-12 mb-3"
    >
      <div className="card h-100 justify-content-start align-items-start justify-content-md-center align-items-md-center shadow-lg border-0 selectedTest_card_container">
        <div className="selectedTest_card_corner">
          {/* <img className="img-fluid" src={selected} alt="" /> */}
          <button
            onClick={() => removeFromCartHandler(selectedItem.test)}
            className="border-0 bg-white remove_cart_icon"
          >
            <FontAwesomeIcon
              style={{ color: "#C70A5F", width: "100%", height: "20px" }}
              icon={faTrash}
            />
          </button>
        </div>
        <div className="row justify-content-start align-items-start justify-content-md-center align-items-md-center">
          <div className="col-md-4">
            <img
              className="img-fluid pl-0 pl-md-2 pl-lg-4 pl-sm-5 pr-0 pr-md-0 pr-lg-0 pr-sm-5 pt-0 pt-md-0 pt-lg-4 pt-sm-5 pb-0 pb-md-0 pb-lg-4 selectedTest_card_img"
              src={selectedItem.image}
              alt="..."
            />
          </div>
          <div className="col-md-8 mt-4">
            <div className="card-body m-0 p-0 pl-md-0 pl-sm-2 selectedTest_card_body">
              <h5 className="card-title" style={{ color: "#BD0451" }}>
                {selectedItem.name}
              </h5>
              <p className="card-text pr-2 pb-2">{selectedItem.details}</p>
            </div>
          </div>
        </div>
        <div className="view-detail-test-card-corner">
          <span
            onClick={() =>
              handleTestModalData(selectedItem.test, selectedItem.name)
            }
            //onMouseDown={() => setShow(true)}
            //data-toggle="modal"
            //data-target="#exampleModal"
            className="border-0 bg-white"
            style={{ color: "#bd0451" }}
          >
            View Details{">"}
          </span>
          {/**  */}
        </div>
      </div>

      {/** Modal
       * <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {!testName ? "" : testName}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <div className="row">
                  <div className="col-3">
                    <img src={lalpathlabs} alt="lalpathlabs" height={70} />
                  </div>
                  <div className="col-6 mt-4">
                    <h5 className="">Dr. Lal Pathlabs</h5>
                  </div>
                  <div className="col-3 ">
                    {lalPathlabsTestData?.Missing_test === [] ? (
                      <span>
                        <p className="mt-2" style={{ color: "red" }}>
                          Test Not Available
                        </p>
                      </span>
                    ) : (
                      <>
                        <span className="row">
                          Test Price: {lalPathlabsTestData?.Sub_Total}
                        </span>
                        <span className="row">
                          Discount: - {lalPathlabsTestData?.Discount}%
                        </span>
                        <span className="row">
                          Total Price:
                          {lalPathlabsTestData?.Sub_Total_After_Discount}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       */}
      <TestDetailsModal
        show={show}
        handleClose={handleClose}
        name={testName}
        lalPathlabsTestData={lalPathlabsTestData}
        unitedTestData={unitedTestData}
        ibnSinaTestData={ibnSinaTestData}
        popularTestData={popularTestData}
        pravehealthTestData={pravehealthTestData}
      ></TestDetailsModal>
    </motion.div>
  );
};

export default SelectedTestDetails;
