import axios from "axios";
import { CART_ADD_ITEM, CART_REMOVE_ITEM } from "../Type";

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const configures = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
  };

  const { data } = await axios.get(
    `https://www.api-care-box.click/api/v2/pathology/Medical_testDetail/${id}/`,
    configures
  );

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      test: data.id,
      name: data.TestName,
      image: data.image,
      details: data.Details,
      qty,
    },
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};
