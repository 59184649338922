import React from "react";
import Modal from "react-modal";
import "./missingTestModal.css";
import pic from "../../Assets/error.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "30px",
    boxShadow: "1px 1px 10px grey",
  },
};

const MissingTestModal = (props) => {
  const { closeModal, modalIsOpen, missingTest } = props;

  //   console.log("MissingTest", missingTest);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      //ariaHideApp={false}
      contentLabel="Example Modal"
    >
      <div className="text-center py-2 px-5">
        <img className="imageModal" src={pic} alt="alt" />
        <h4 className="unavailable_center">
          Unavailable for this diagnostic center:
        </h4>
        {missingTest ? (
          <>
            {missingTest.map((item, index) => (
              <h5>
                {" "}
                (<span>{index + 1}</span>) {item.Missing_test_name}
              </h5>
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
    </Modal>
  );
};

export default MissingTestModal;
