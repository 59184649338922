import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getUserDetails } from "../../Redux/Actions/userActions";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../Profile/profile.css";

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const locationset = useLocation();
  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;

  useEffect(() => {
    if (error) {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);
  return (
    <section>
      <Header></Header>
      <div className="container mb-4 mt-4"></div>
      <Footer></Footer>
    </section>
  );
};

export default Profile;
