import "./App.css";
import Home from "./Components/Home/Home";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Map from "./Components/Map/Map";
import PatientForm from "./Components/PatientForm/PatientForm";
import AvailableTests from "./Components/AvailableTests/AvailableTests";
import SelectedTests from "./Components/SelectedTests/SelectedTests";
import PricingOptions from "./Components/PricingOptions/PricingOptions";
import Dashboard from "./Components/Dashboard/Dashboard";
import Login from "./Components/Login/Login";
import Register from "./Components/Login/Register";
import PrivateRoute from "./Components/Login/PrivateRoute";
import PaymentLoading from "./Components/Payments/PaymentLoading";
import PaymentFailure from "./Components/Payments/PaymentFailure";
import PaymentSuccess from "./Components/Payments/PaymentSuccess";
import PaymentCancel from "./Components/Payments/PaymentCancel";
import Profile from "./Components/Profile/Profile";
import { motion } from "framer-motion";
import Doctor from "./Components/VideoChat/Doctor";
import Patient from "./Components/VideoChat/Patient";
import AboutUs from "./Components/FooterContent/AboutUs";
import PrivacyPolicy from "./Components/FooterContent/PrivacyPolicy";
import ReturnPolicy from "./Components/FooterContent/ReturnPolicy";
import TermsCondition from "./Components/FooterContent/TermsCondition";
import SearchResultTest from "./Components/AvailableTests/SearchResultTest";
import NewLogin from "./Components/Login/NewLogin/NewLogin";
import NewRegister from "./Components/Login/NewRegister/NewRegister";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/terms-condition">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <TermsCondition></TermsCondition>
            </motion.div>
          </Route>
          <Route path="/return-policy">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <ReturnPolicy></ReturnPolicy>
            </motion.div>
          </Route>
          <Route path="/privacy-policy">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PrivacyPolicy></PrivacyPolicy>
            </motion.div>
          </Route>
          <Route path="/about-us">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <AboutUs></AboutUs>
            </motion.div>
          </Route>

          {/* <Route path="/patient">
            <Patient></Patient>
          </Route>
          <Route path="/doctor">
            <Doctor></Doctor>
          </Route> */}
          <Route path="/profile">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Profile></Profile>
            </motion.div>
          </Route>
          <Route path="/paymentcancel">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PaymentCancel></PaymentCancel>
            </motion.div>
          </Route>
          <Route path="/paymentsuccess">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PaymentSuccess></PaymentSuccess>
            </motion.div>
          </Route>
          <Route path="/paymentfailure">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PaymentFailure></PaymentFailure>
            </motion.div>
          </Route>
          <Route path="/paymentloading">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PaymentLoading></PaymentLoading>
            </motion.div>
          </Route>
          <Route path="/register">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <NewRegister />
            </motion.div>
          </Route>
          <Route path="/login">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <NewLogin />
            </motion.div>
          </Route>
          <PrivateRoute path="/dashboard">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Dashboard></Dashboard>
            </motion.div>
          </PrivateRoute>
          <PrivateRoute path="/pricingoptions">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PricingOptions></PricingOptions>
            </motion.div>
          </PrivateRoute>
          <PrivateRoute path="/selectedtests">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <SelectedTests></SelectedTests>
            </motion.div>
          </PrivateRoute>
          <PrivateRoute path="/availabletests">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <AvailableTests></AvailableTests>
            </motion.div>
          </PrivateRoute>
          <PrivateRoute path="/searchResultTest/:id">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <SearchResultTest></SearchResultTest>
            </motion.div>
          </PrivateRoute>
          <PrivateRoute path="/patientform">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <PatientForm></PatientForm>
            </motion.div>
          </PrivateRoute>
          <PrivateRoute exact path="/map">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Map></Map>
            </motion.div>
          </PrivateRoute>
          <Route exact path="/">
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <Home></Home>
            </motion.div>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
