import React, { useEffect, useRef, useState } from "react";
import "../AvailableTests/availableTests.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useHistory } from "react-router-dom";
import axios from "axios";
import AvailableTestDetails from "./AvailableTestDetails";
import { motion } from "framer-motion";
import { getUserDetails } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const AvailableTests = () => {
  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef);
  const history = useHistory();
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  // const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(8);

  useEffect(() => {
    getAvailableTest();
  }, []);

  const getAvailableTest = () => {
    setLoading(true);
    axios
      .get(
        `https://www.api-care-box.click/api/v2/pathology/Medical_Test/?limit=${limit}&offset=${offset}`
      )
      .then((res) => {
        // console.log(res.data);
        const newTests = res.data.medical_test;
        const hasMore = res.data.has_more;
        setHasMore(hasMore);
        setLoading(false);
        setData([...data, ...newTests]);
        setOffset(offset + limit);
      })
      .catch((err) => {
        //console.log(err);
        setLoading(false);
      });
  };

  window.onscroll = () => {
    if (loading || !hasMore) {
      // return console.log("i am error, loading, has not more");
      return;
    } else {
      getAvailableTest();
    }
  };

  const dispatch = useDispatch();
  const locationset = useLocation();
  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;

  useEffect(() => {
    if (error) {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  return (
    <section>
      <Header></Header>
      <div className="container mb-5 mt-md-4">
        <p className="page_title">
          Available <span style={{ color: "#BD0451" }}>Tests</span>
        </p>
        {data.length ? (
          <div className="row">
            {data.map((eachData, i) => {
              return (
                <AvailableTestDetails
                  key={i}
                  availableCard={eachData}
                ></AvailableTestDetails>
              );
            })}
            {loading && (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-secondary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <Footer></Footer>
    </section>
  );
};

export default AvailableTests;
