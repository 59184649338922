import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../Media/logo.png";
import Footer from "../Footer/Footer";
import "./FooterContent.css";
import "aos/dist/aos.css";
import Aos from "aos";

const PrivacyPolicy = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <Navbar className="footer_navbar">
        <Navbar.Brand style={{ margin: "0 0 0 0" }}>
          <img src={logo} onClick={() => history.push("/")} alt="logo" fluid />
        </Navbar.Brand>
      </Navbar>
      <div className="about_us user-select-none">
        <h2 data-aos="fade-up">Privacy Policy</h2>
        <p data-aos="fade-up">
          <p data-aos="fade-up" id="footP">
            Welcome to the Online doctor portal website. We respect your privacy
            and want to protect your personal information. To learn more, please
            read this Privacy Policy..
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            This Privacy Policy explains how we collect, use and (under certain
            conditions) disclose your personal information. This Privacy Policy
            also explains the steps we have taken to secure your personal
            information. Finally, this Privacy Policy explains your options
            regarding the collection, use and disclosure of your personal
            information. By visiting the Site directly or through another site,
            you accept the practices described in this Policy.
          </p>
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            Data protection is a matter of trust and your privacy is important
            to us. We shall therefore only use your name and other information
            which relates to you in the manner set out in this Privacy Policy.
            We will only collect information where it is necessary for us to do
            so and we will only collect information if it is relevant to our
            dealings with you.
          </p>
          <br />
          <br />
          <p data-aos="fade-up">
            We will only keep your information for Three Years before deleting
            it completely from our servers. You can visit the Site and browse
            without having to provide personal details. During your visit to the
            Site you remain anonymous and at no time can we identify you unless
            you have an account on the Site and log on with your user name and
            password.
          </p>
        </p>
        <br />
        <h4 data-aos="fade-up">1.1 Data that We Collect</h4>
        <p data-aos="fade-up">
          We may collect various pieces of information if you seek to place a
          test booking with us on the Site.We collect, store and process your
          data for processing your purchase on the Site and any possible later
          claims, and to provide you with our services. We may collect personal
          information including, but not limited to, your title, name, gender,
          date of birth, email address, postal address, delivery address (if
          different), telephone number, mobile number, fax number, payment
          details, payment card details or bank account details.
        </p>
        <br />
        <p data-aos="fade-up">
          The data is collected so that we can serve you. At first the necessary
          data is taken so that we can provide you with the pathology services
          by going to your homes and collecting the sample. After that we
          deliver the hard copy to you. Furthermore, we will use the information
          you provide to administer your account with us; verify and carry out
          financial transactions in relation to payments you make; audit the
          downloading of data from our website; improve the layout and/or
          content of the pages of our website and customize them for users;
          identify visitors on our website; carry out research on our users'
          demographics; send you information we think you may find useful or
          which you have requested from us, including information about our
          products and services, provided you have indicated that you have not
          objected to being contacted for these purposes. Subject to obtaining
          your consent we may contact you by email with details of other
          products and services. If you prefer not to receive any marketing
          communications from us, you can opt out at any time.
        </p>
        <br />
        <p data-aos="fade-up">
          Your actual order details may be stored with us but for security
          reasons which cannot be retrieved directly by us. However, you may
          access this information by logging into your account on the Site. Here
          you can view the details of your pathology test orders that have been
          completed, those which are open and those which are shortly to be
          dispatched and administer your address details, bank details (for
          refund purposes) and any newsletter to which you may have subscribed.
        </p>
        <br />
        <h4 data-aos="fade-up">1.2 Other uses of your personal information</h4>
        <p data-aos="fade-up">
          We may use your personal information for opinion and market research.
          Your details are anonymous and will only be used for statistical
          purposes. You can choose to opt out of this at any time. Any answers
          to surveys or opinion polls we may ask you to complete will not be
          forwarded on to third parties.
          <br />
        </p>
        <br />
        <p data-aos="fade-up">
          We may also send you other information about us, the Site, our other
          websites, our products, sales promotions, our newsletters, anything
          relating to other companies in our group or our business partners. If
          you would prefer not to receive any of this additional information as
          detailed in this paragraph (or any part of it) please click the
          'unsubscribe' link in any email that we send to you.
        </p>
        <br />
        <p data-aos="fade-up">
          For any competition we use the data to notify winners and advertise
          our offers. You can find more details where applicable in our
          participation terms for the respective competition.
        </p>
        <br />
        <h4 data-aos="fade-up">1.3 Cookies</h4>
        <p data-aos="fade-up">
          The acceptance of cookies is not a requirement for visiting the Site.
          However, we would like to point out that the use of the ‘basket’
          functionality and ‘login’ functionality on the Site requires the use
          of cookies. Cookies are tiny text files which identify your computer
          to our server as an unique user when you visit certain pages on the
          Site and they are stored by your Internet browser on your computer's
          hard drive. Cookies can be used to recognize your Internet Protocol
          address, saving you time while you are on, or want to enter, the Site
        </p>
        <br />
        <h4 data-aos="fade-up">1.4 Security</h4>
        <p data-aos="fade-up">
          After collecting your data, we store it on a secure server. Moreover,
          the server is protected by firewalls. Our security procedures mean
          that we may occasionally request proof of identity before we disclose
          personal information to you.
        </p>
        <br />
        <h4 data-aos="fade-up">1.5 Rights</h4>
        <p data-aos="fade-up">
          If you are concerned about your data you have the right to request
          access to the personal data which we may hold or process about you. At
          any stage you also have the right to ask us to stop using your
          personal data for direct marketing purposes.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
