import React, { useEffect, useRef, useState } from "react";
import "../AvailableTests/availableTests.css";
import selected from "../../Media/selected.png";
import add from "../../Media/add.png";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import AvailableTestDetails from "./AvailableTestDetails";
import { motion } from "framer-motion";
import { getUserDetails } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useHistory } from "react-router-dom";

const SearchResultTest = () => {
  const param = useParams();
  const [testData, setTestData] = useState();
  useEffect(() => {
    getSearchResultTest();
  }, [param]);
  //console.log(param)
  const getSearchResultTest = async () => {
    await axios
      .get(
        `https://www.api-care-box.click/api/v2/pathology/Medical_testDetail/${param.id}/`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        setTestData(res.data);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  return (
    <section>
      <Header></Header>
      <div className="container mb-5 mt-md-4">
        <p className="page_title">
          Available <span style={{ color: "#BD0451" }}>Tests</span>
        </p>
        {param.id && testData ? (
          <div className="row">
            <AvailableTestDetails
              availableCard={testData}
            ></AvailableTestDetails>
          </div>
        ) : (
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        )}
      </div>
      <Footer></Footer>
    </section>
  );
};

export default SearchResultTest;

/** 
 * const addToCartHandler = () => {
    //setCounter(2);
    setTimeout(() => {
      history.push("/selectedtests");
    }, 500);
  };

  const selectedTest = useSelector((state) => state.cart);
  const { cartItems } = selectedTest;
  // console.log("selected Test in available test", cartItems);

  const isItemExist = (id) => {
    return cartItems.findIndex((item) => item.test === id) === -1;
  };
 * <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="col-lg-4 col-sm-6 mb-3"
            >
              <div
                onClick={addToCartHandler}
                class="card h-100 justify-content-start align-items-start justify-content-md-center align-items-md-center shadow-lg border-0 availableTest_card_container"
              >
                <div className="availableTest_card_corner">
                  {isItemExist(testData.id) ? (
                    <>
                      <div className="availableTest_card_corner_addText">
                        Add
                      </div>
                      <img className="img-fluid corner_img" src={add} alt="" />
                    </>
                  ) : (
                    <>
                      <div className="availableTest_card_corner_addText">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      <img
                        className="img-fluid corner_img"
                        src={selected}
                        alt=""
                      />
                    </>
                  )}
                </div>
                <div class="row justify-content-start align-items-start justify-content-md-center align-items-md-center">
                  <div class="col-md-4">
                    <img
                      className="img-fluid pl-0 pl-md-2 p-0 p-md-0 p-sm-5 availableTest_card_img"
                      src={testData.image}
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8 mt-4">
                    <div class="card-body m-0 p-0 pl-md-0 pl-sm-2 availableTest_card_body">
                      <h5 class="card-title" style={{ color: "#BD0451" }}>
                        {testData.TestName}
                      </h5>
                      <p class="card-text pr-2 pb-2">{testData.Details}</p>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
 */
