import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import firebase from "../firebase.config";
import OtpLoader from "../OtpLoader";
import registerImg from "../../../Media/Group-input.png";
import Footer from "../../Footer/Footer";
import { register } from "../../../Redux/Actions/userActions";
import logo from "../../../Assets/final-logo.png";

const swalFire = () => {
  Swal.fire({
    position: "top",
    icon: "error",
    title: "Oops...",
    text: "User with this Phone Number already exists",
    showConfirmButton: false,
    timer: 2000,
  });
};

const NewRegister = () => {
  const history = useHistory();
  const [numberdisplay, setNumberdisplay] = useState("block");
  const [otpdisplay, setOtpdisplay] = useState("none");
  const [message, setMessage] = useState(
    "The number is not valid number and must use (+88) country code"
  );

  const [number, setNumber] = useState("+88");
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [messageshow, setMessageshow] = useState(false);
  const [otpsuccess, setOtpsuccess] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [afterOtpLoading, setAfterOtpLoading] = useState(false);
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    setOtpLoading(true);
    setMessageshow(false);

    if (number.trim().length !== 14) {
      setOtpLoading(false);
      return setMessageshow(true);
    }

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user/register/?version=otp`,
        { Name: username.trim(), Phone: number.trim() },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.message && response?.data?.otp_status === "sent") {
        // console.log("response.data.message = ", response.data.message);
        // console.log("response.data.otp_status = ", response.data.otp_status);
        setOtpLoading(false);
        //console.log("OTP has been sent");
        setNumberdisplay("none");
        setOtpdisplay("block");
      } else {
        setOtpLoading(false);
        alert("Otp is not send for uncertain reason.Try again later");
      }

      // console.log("response = ", response);
    } catch (error) {
      setOtpLoading(false);
      // console.log("error = ", error);
      if (error?.response?.data?.message) {
        // console.log(error.response.data.message);
        if (
          error.response.data.message === "user with this Phone already exists."
        ) {
          swalFire();
        } else {
          alert("SomeThing Went Wrong");
        }
      } else {
        alert("SomeThing Went Wrong");
      }
    }
  };

  const onSubmitOTP = async (e) => {
    e.preventDefault();
    setAfterOtpLoading(true);
    const code = otp;
    //console.log(code);

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user/otp_login/`,
        { Phone: number.trim(), otp: code.trim() },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem("success", ""); //don't know, just paste from previous code

      // console.log("response = ", response);
      // console.log("response.data.access = ", response.data.access);
      // console.log("response.data.refresh = ", response.data.refresh);
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);

      // copy paste below codes

      localStorage.setItem("registerd", "done");
      if (localStorage.getItem("url")) {
        history.push(localStorage.getItem("url"));
      } else {
        history.push("/");
      }
    } catch (error) {
      setAfterOtpLoading(false);
      // console.log("error = ", error);

      Swal.fire({
        position: "top",
        icon: "error",
        title: "Oops...",
        text: "Incorrect OTP, Please Provide Valid OTP",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <section>
      <div className="container">
        <div className="text-center p-4">
          <img
            onClick={() => history.push("/")}
            style={{ cursor: "pointer", height: "80px" }}
            className="img-fluid"
            src={logo}
            alt="..."
          ></img>
        </div>
        <div className="row justify-content-between pt-3 pb-3 mt-2 mb-2">
          <div className="col-md-6 text-center text-md-left">
            <h1 style={{ color: "#bd095a" }}>স্বাগতম,</h1>

            {numberdisplay === "block" ? (
              <h4 style={{ fontFamily: "kalpurush", lineHeight: "150%" }}>
                অনুগ্রহ করে আপনার নাম লিখুন......
              </h4>
            ) : (
              <h4 style={{ fontFamily: "kalpurush", lineHeight: "150%" }}>
                এস এম এস এ প্রদত্ত OTP টি প্রদান করুন.........
              </h4>
            )}

            <img className="img-fluid" src={registerImg} alt="..." />
          </div>
          <div className="col-md-1 p-0 border_left"></div>
          <div className="col-md-5 align-self-center text-center mt-4 pt-5 mt-md-0 pt-md-0">
            <form
              onSubmit={onSignInSubmit}
              style={{ display: `${numberdisplay}` }}
            >
              <h4>মোবাইল নাম্বার:</h4>
              <div id="sign-in-button"></div>
              <input
                className="mobile_field"
                style={{ opacity: "35%" }}
                type="text"
                name="mobile"
                required
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />

              <h4 className="mt-3">রোগীর নাম:</h4>
              <input
                className="mobile_field"
                style={{ opacity: "35%" }}
                type="text"
                name="mobile"
                required
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              <br></br>

              {otpLoading ? <OtpLoader /> : <h4></h4>}
              <div id="recaptcha-container"></div>
              <button className="mt-3 registerButton" type="submit">
                প্রবেশ করুন
              </button>
            </form>
            <br></br>

            <form onSubmit={onSubmitOTP} style={{ display: `${otpdisplay}` }}>
              <h4>OTP</h4>
              <input
                className="mobile_field"
                style={{ opacity: "35%" }}
                type="text"
                name="otp"
                required
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
              <br />
              {afterOtpLoading ? <OtpLoader /> : <h4></h4>}
              <button
                className="mt-3 registerButton"
                type="submit"
                onClick={() => setAfterOtpLoading(true)}
              >
                যাচাই করুন
              </button>
            </form>
            <br></br>
            {messageshow ? (
              <p
                style={{
                  color: "#CC0A61",
                  textAlign: "center",
                  //   paddingRight: "23%",
                }}
              >
                {message}
              </p>
            ) : (
              <p></p>
            )}
            <label></label>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default NewRegister;
