import React, { useEffect, useState } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import formImg from "../../Media/google-forms.png";
import "../PatientForm/patientForm.css";
import { useHistory } from "react-router-dom";
import HeaderWithoutSearch from "../Header/HeaderWithoutSearch";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "../../Redux/Actions/userActions";
import Swal from "sweetalert2";

const PatientForm = () => {
  const history = useHistory();

  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [gender, setGender] = useState();
  const [age, setAge] = useState();
  const [note, setNote] = useState("");

  const patientFormDetail = {
    name: name,
    phone: phone,
    gender: gender,
    age: age,
    note: note,
  };

  const handleBlur = (e) => {
    let isFormValid = true;
    if (e.target.name === "phone") {
      isFormValid = /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/.test(
        e.target.value
      );
    }
    if (isFormValid) {
      const newPhone = { ...phone };
      newPhone[e.target.name] = e.target.value;
      setPhone(newPhone);
    } else {
      Swal.fire({
        position: "top",
        icon: "error",
        title: "Invalid Phone Number",
        text: "Provide correct phone number",
        showConfirmButton: false,
        timer: 4000,
      });

      const newPhone = { ...phone };
      newPhone[e.target.name] = e.target.value;
      setPhone(newPhone);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("patientForm", JSON.stringify(patientFormDetail));
    history.push("/pricingoptions");
  };

  const dispatch = useDispatch();
  const locationset = useLocation();
  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  //console.log(error);

  useEffect(() => {
    if (error) {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  return (
    <section>
      <Header></Header>
      {/* <HeaderWithoutSearch></HeaderWithoutSearch> */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="container mb-5 mt-md-4"
      >
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8 order-2 order-md-1">
            <form className="patient_form" onSubmit={handleSubmit}>
              <p>
                Patient <span style={{ color: "#BD0451" }}>Form</span>
              </p>

              <div className="form-group">
                <label>Patient Name:</label>
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label>Mobile Number:</label>
                <input
                  type="tel"
                  className="form-control"
                  name="phone"
                  // onChange={(e) => setPhone(e.target.value)}
                  onBlur={handleBlur}
                  required
                />
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>Gender:</label>
                  <br />
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="Male"
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />
                    <label className="form-check-label">Male</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="Female"
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />
                    <label className="form-check-label">Female</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio3"
                      value="Other"
                      onChange={(e) => setGender(e.target.value)}
                      required
                    />
                    <label className="form-check-label">Other</label>
                  </div>
                </div>
                <div className="form-group col-md-2 col-sm-3 col-4">
                  <label>Age:</label>
                  <input
                    type="number"
                    className="form-control"
                    onChange={(e) => setAge(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Note:</label>
                <textarea
                  rows="4"
                  cols="50"
                  className="form-control"
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>

              <button type="submit">View Test</button>
            </form>
          </div>
          <div className="col-md-4 order-1 order-md-2">
            <div className="d-flex justify-content-center align-items-center">
              <img
                className="img-fluid patient_form_img"
                src={formImg}
                alt=""
              />
            </div>
          </div>
        </div>
      </motion.div>
      <Footer></Footer>
    </section>
  );
};

export default PatientForm;
