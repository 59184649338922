import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../SelectedTests/selectedTests.css";
import SelectedTestDetails from "./SelectedTestDetails";
import add from "../../Media/add.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "../../Redux/Actions/userActions";

const SelectedTests = () => {
  const dispatch = useDispatch();
  const selectedTest = useSelector((state) => state.cart);
  const { cartItems } = selectedTest;
  // console.log("selected Test", cartItems);

  const history = useHistory();

  const locationset = useLocation();
  const userDetails = useSelector((state) => state.userDetails);
  const { error, user } = userDetails;

  useEffect(() => {
    if (error) {
      history.push("/login");
      localStorage.setItem("url", locationset.pathname);
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  return (
    <section>
      <Header></Header>
      <div className="container mb-5 mt-md-4">
        <p className="page_title">
          Selected <span style={{ color: "#BD0451" }}>Tests</span>
        </p>
        {cartItems.length ? (
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                {cartItems.map((selectedItem , i) => {
                  return (
                    <SelectedTestDetails
                      key={i}
                      selectedItem={selectedItem}
                    ></SelectedTestDetails>
                  );
                })}
              </div>
            </div>
            <div className="col-md-4 add_more">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/availabletests")}
                className="card shadow-lg border-0"
              >
                <div className="p-3 text-center">
                  <img className="img-fluid" src={add} alt="..." />
                  <p className="pt-2 m-0">Add More Tests</p>
                </div>
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <button
                  className="shadow-lg"
                  onClick={() => history.push("/map")}
                >
                  View Pricing
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div className="h-100 d-flex justify-content-center add_more p-4">
                <button
                  onClick={() => history.push("/availabletests")}
                  className="align-self-center"
                >
                  Please Add a Test to Continue
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer></Footer>
    </section>
  );
};

export default SelectedTests;
