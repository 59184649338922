import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/home.css";
import HomeSlider from "./HomeSlider";
import Header from "../Header/Header";
import HomeInfo from "./HomeInfo";
import HomeMainContent from "./HomeMainContent";
import Footer from "../Footer/Footer";
import HeaderWithoutSearch from "../Header/HeaderWithoutSearch";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <div>
      {/* <HeaderWithoutSearch></HeaderWithoutSearch> */}

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Header></Header>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <HomeSlider></HomeSlider>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <HomeInfo></HomeInfo>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <HomeMainContent></HomeMainContent>
      </motion.div>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        <Footer></Footer>
      </motion.div>
    </div>
  );
};

export default Home;
