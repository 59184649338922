import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";

const Search = () => {
  const history = useHistory();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(3);
  // console.log(keyword);

  useEffect(() => {
    searchData();
  }, [keyword]);

  const handledown = () => {
    setData([]);
    setOffset(0);
  };

  const searchData = () => {
    setLoading(true);
    if (keyword.length > 1) {
      //console.log("outside keyword", keyword);
      axios
        .get(
          `https://www.api-care-box.click/api/v2/pathology/Medical_test/ListofMedical_TestInfiniteScrollPage_With_Keyword/?limit=${limit}&offset=${offset}&keyword=${keyword}`
        )
        .then((res) => {
          //console.log("whole", res.data);
          // console.log("offset", offset);
          // console.log("limit", limit);
          const newData = res.data.medical_test;
          const hasMoreApi = res.data.has_more;
          setHasMore(hasMoreApi);
          setLoading(false);
          setData([...data, ...newData]);
          setOffset(offset + limit);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
    if (keyword.length < 1) {
      setData([]);
    }
  };

  const DivScroll = () => {
    if (error || loading || !hasMore) {
      // return console.log("i am error, loading, has not more");
      return;
    } else {
      // call some loading method
      // console.log("call from documentElement");
      searchData();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(keyword);
    history.push("/availabletests");
  };

  // outside click
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setData([]);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className="style_container">
      <form
        className="d-flex justify-content-center align-items-center"
        onSubmit={handleSubmit}
      >
        <div className="wrap">
          <div className="search mt-3 mt-md-0">
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search for tests..."
              className="searchTerm"
              onKeyDown={handledown}
            />
          </div>
        </div>
      </form>

      {data.length > 0 && keyword.length > 1 ? (
        <div className="d-flex justify-content-center">
          <div
            className="autocomplete-items1"
            onScroll={DivScroll}
            ref={wrapperRef}
          >
            {data.map((each, i) => {
              return (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  key={i}
                >
                  <div
                    onClick={() => history.push(`/searchResultTest/${each.id}`)}
                    className="items_serial1"
                  >
                    <div className="d-flex align-items-center">
                      <img className="rounded-circle" src={each.image} alt="" />
                    </div>
                    <div className="ml-2 align-self-center">
                      <p className="m-0" style={{ color: "#BD095A" }}>
                        {each.TestName}
                      </p>
                      <br />
                      {/** <h6 style={{ opacity: "75%", color: "black" }}>
                          Details : {each.Details}
                        </h6> */}
                    </div>
                  </div>
                </motion.div>
              );
            })}
            {hasMore && (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                scroll to load more
              </div>
            )}
            {error && (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                error: {error}
              </div>
            )}
            {loading && (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                loading....
              </div>
            )}
            {!hasMore && (
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              ></div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Search;
