import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "../Map/map.css";
// import {
//   GoogleMap,
//   LoadScript,
//   DirectionsService,
//   DirectionsRenderer,
// } from "@react-google-maps/api";
// import { Marker } from "@react-google-maps/api";

import GoogleMapReact from "google-map-react";
import { Marker } from "google-map-react";
import Geocode from "react-geocode";
import marker from "../../Media/marker.png";
import HeaderWithoutSearch from "../Header/HeaderWithoutSearch";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../Redux/Actions/userActions";

Geocode.setApiKey("AIzaSyDqdMhtdf2pIqsMrOyDCMrQja1EiMCgZoU");
Geocode.enableDebug();

const Map = () => {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [address, setAddress] = useState();
  const [house, setHouse] = useState();
  const [flat, setFlat] = useState();
  const allAddress = { area: address, house: house, flat: flat };

  // console.log(allAddress);

  const history = useHistory();
  // const dispatch = useDispatch();
  // const locationset = useLocation();
  // const userDetails = useSelector((state) => state.userDetails);
  // const { loading, error, user } = userDetails;
  // console.log(error);

  // useEffect(() => {
  //   if (error) {
  //     history.push("/login");
  //     localStorage.setItem("url", locationset.pathname);
  //     window.location.reload();
  //   }
  // }, [error]);

  // useEffect(() => {
  //   dispatch(getUserDetails());
  // }, []);

  const center = {
    lat: latitude,
    lng: longitude,
    // lat: "25.329",
    // lng: "89.5415",
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);

      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
        // "25.329","89.5415"
      ).then(
        (response) => {
          // const address = response.results[0].formatted_address;
          // console.log("response", response.results);
          // setAddress(address);

          response.results.map((item, index) => {
            // console.log("item", item);
            if (item.types[0] === "street_address") {
              const address = response.results[index].formatted_address;
              //console.log("my address", address);
              setAddress(address);
            }
          });
        },
        (error) => {
          console.error(error);
        }
      );
    });
  }, []);

  const AnyReactComponent = ({ marker }) => <div>{marker}</div>;

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("address", JSON.stringify(allAddress));
    history.push("/patientform");
  };

  return (
    <section>
      <Header></Header>
      {/* <HeaderWithoutSearch></HeaderWithoutSearch> */}
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-8 mt-md-5 mb-3">
            <div className="m-1">
              <div className="google_map shadow-sm">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyDqdMhtdf2pIqsMrOyDCMrQja1EiMCgZoU",
                  }}
                  defaultCenter={center}
                  center={center}
                  defaultZoom={16}
                  options={""}
                  onChange={""}
                  onChildClick={""}
                >
                  <AnyReactComponent
                    lat={latitude}
                    lng={longitude}
                    // lat={59.955413}
                    // lng={30.337844}
                    marker={
                      <img
                        style={{
                          height: "30px",
                          width: "30px",
                          // border: "1px solid red",
                          // borderRadius: "50%",
                          // padding: "5px",
                        }}
                        src={marker}
                        alt="..."
                      />
                    }
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>
          <div className="col-md-4 mt-md-5">
            <div className="m-1">
              <div className="locate_me">
                <h3 className="text-center">Locate Me</h3>
              </div>
              <p className="mt-3" style={{ fontFamily: "poppins" }}>
                Your Address
              </p>
              <motion.form
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="map_form"
                onSubmit={handleSubmit}
              >
                <div class="form-group">
                  <label>Address:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>House:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setHouse(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label>Apt./ Flat:</label>
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setFlat(e.target.value)}
                    required
                  />
                </div>
                <button type="submit">Confirm</button>
              </motion.form>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default Map;
