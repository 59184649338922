import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../../Media/logo.png";
import Loader from "../Loader/Loader";

const PaymentLoading = () => {
  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("ssl_book") === "patient_applied") {
      fetch(
        "https://www.api-care-box.click/api/v2/pathology/Pathlogy_Payment/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => {
          //console.log(data);
          if (data) {
            window.location.href = data;
          } else {
            Swal.fire({
              position: "top",
              icon: "error",
              title: "Something is wrong!",
              text: "Please, try again. . .",
              showConfirmButton: false,
              timer: 2000,
            });
          }
        })
        .catch((err) => {
          //console.log("an issue", err)
        });
    }
  }, [localStorage.getItem("ssl_book")]);

  return (
    <section>
      <div className="container mb-5 pb-5">
        <div className="text-center p-4">
          <img className="img-fluid" src={logo} alt="..."></img>
        </div>
        <div className="text-center mt-5 pt-5">
          <Loader></Loader>
          <h3
            className="mt-5"
            style={{
              color: "#cc0a61",
              fontFamily: "poppins",
              //   fontWeight: "bold",
            }}
          >
            Your booking is shifting into payment getway. . .
          </h3>
        </div>
      </div>
    </section>
  );
};

export default PaymentLoading;
