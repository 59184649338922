import React from "react";
import "../Footer/footer.css";
import ssl2 from "../../Assets/ssl2.jpg";
import ssl from "../../Assets/ssl.png";
import facebook from "../../Assets/facebook.png";
import instagram from "../../Assets/instagram.png";
import whatsapp from "../../Assets/whatsapp.png";
import email from "../../Assets/email.png";
import { useHistory } from "react-router-dom";
import appIos from "../../Assets/app-ios.png";
import appPlay from "../../Assets/app-play.png";

function Footer() {
  let history = useHistory();

  return (
    <section>
      <div className="container8_full_width">
        <div className="up_content">
          <div className="content">
            <h2>Download Care-Box App From</h2>
            <div className="our_app">
              <a href="https://play.google.com/store/apps/details?id=care_box.carebox.com">
                <img className="img-fluid" src={appPlay} alt="" />
              </a>
              <a href="https://apps.apple.com/us/app/care-box-all-cares-inside/id1610855749">
                <img className="img-fluid" src={appIos} alt="" />
              </a>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center pt-5">
          <div className="row text-center w-75">
            <div className="col-lg-3 col-md-6 find_us pb-5">
              <h4>Find Us</h4>
              <div className="d-flex flex-column align-items-center">
                <a href="https://www.facebook.com/careboxlimited">
                  <img className="img-fluid" src={facebook} alt="..." />
                </a>
                <a href="https://api.whatsapp.com/send?phone=+8801796020709">
                  <img className="img-fluid" src={whatsapp} alt="..." />
                </a>
                <a href="https://www.instagram.com/careboxlimited/">
                  <img className="img-fluid" src={instagram} alt="..." />
                </a>
                <a href="mailto:my@care-box.com">
                  <img className="img-fluid" src={email} alt="..." />
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 address pb-5">
              <h4>Address</h4>
              <p className="mt-3">
                149/A Monipuri Para, (Ground Floor) Airport Road, Farmgate,
                Tejgaon, Dhaka-1215, Bangladesh
              </p>
            </div>

            <div className="col-lg-3 col-md-6 about pb-5">
              <h4>About</h4>
              <div className="mt-3">
                <h6
                  onClick={() => {
                    history.push("/about-us");
                  }}
                >
                  About Us
                </h6>
                {/* <h6>Career</h6> */}
                <h6
                  onClick={() => {
                    history.push("/return-policy");
                  }}
                >
                  Return Policy
                </h6>
                <h6
                  onClick={() => {
                    history.push("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </h6>
                <h6
                  onClick={() => {
                    history.push("/terms-condition");
                  }}
                >
                  Terms & Conditions
                </h6>
                <a
                  id="carebox_tech"
                  href="https://www.care-box.tech/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <h6>Care-Box Tech Solutions</h6>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 payment_methods pb-5">
              <h4>Payment Methods</h4>
              <img
                className="img-fluid mt-2 rounded-lg shadow-sm"
                src={ssl2}
                alt=""
              />
            </div>
          </div>
        </div>
        {/* <div className="d-flex justify-content-center payment_methods p-3">
          <img className="img-fluid border border-rounded" src={ssl} alt="" />
        </div> */}

        <div className="d-flex justify-content-center text-center user-select-none">
          <div className="pt-3 pb-2">
            <p className="m-0">Trade License No: 289236</p>
            <p className="m-0 font-weight-bold">
              All rights reserved by @Care-Box Limited,{" "}
              {new Date().getFullYear()}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
