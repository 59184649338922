import React from "react";
import microscope from "../../Media/microscope.png";
import time from "../../Media/time.png";
// import delivery from "../../Media/delivery-box.png";
import delivery from "../../Media/document.png";

const HomeMainContent = () => {
  return (
    <section>
      <div className="container p-lg-0 main_content mt-4 user-select-none">
        <h3>
          <span style={{ color: "#BD0451" }}>What</span> you will get?
        </h3>
        <div className="row my-4 py-4">
          <div className="col-md-5 order-1 order-md-1">
            <div className="d-flex justify-content-center">
              <img className="img-fluid" src={microscope} alt="" />
            </div>
          </div>
          <div className="col-md-7 order-2 order-md-2">
            <div className="d-flex justify-content-center flex-column h-100">
              <p className="text-justify mt-3 mt-md-0">
                Premium pathology services are brought to you by our Online
                Doctor portal website. We have a range of different tests to
                cater to your services. You can check to see if you have your
                desired tests available.
              </p>
            </div>
          </div>
        </div>

        <div className="row my-4 py-4">
          <div className="col-md-5 order-1 order-md-2">
            <div className="d-flex justify-content-center">
              <img className="img-fluid" src={time} alt="" />
            </div>
          </div>
          <div className="col-md-7 order-2 order-md-1">
            <div className="d-flex justify-content-center flex-column h-100">
              <p className="text-justify mt-3 mt-md-0">
                We will take a schedule according to your feasibility. We do not
                impose our schedule on anyone. According to the scheduled time,
                an experienced and professional medical assistant will be there,
                in front of your doorstep, to collect your sample.
              </p>
            </div>
          </div>
        </div>

        <div className="row my-4 py-4">
          <div className="col-md-5 order-1 order-md-1">
            <div className="d-flex justify-content-center">
              <img className="img-fluid" src={delivery} alt="" />
            </div>
          </div>
          <div className="col-md-7 order-2 order-md-2">
            <div className="d-flex justify-content-center flex-column h-100">
              <p className="text-justify mt-3 mt-md-0">
                Test report will be delivered to you, within 24 hours of getting
                the test result. The report will be delivered to your doorsteps,
                without any additional charges.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeMainContent;
