import React, { useState } from "react";
import "./reviewmodal.css";
import Modal from "react-modal";
import { Rating } from "react-simple-star-rating";
import Swal from "sweetalert2";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "30px",
    boxShadow: "1px 1px 10px grey",
  },
};

const ReviewModal = (props) => {
  const { closeModal, modalIsOpen, reviewDiagnosticId } = props;

  const [ratingValue, setRatingValue] = useState(0);
  const [comment, setComment] = useState("");

  const handleRating = (rate) => {
    setRatingValue(rate / 20);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("Rating", ratingValue);
    formData.append("Review_Description", comment);
    formData.append("DiagnosticCenter", reviewDiagnosticId);

    fetch(
      "https://www.api-care-box.click/api/v2/pathology/diagnostic_center_post_review/",
      {
        method: "POST",
        body: formData,
        headers: {
          "Custom-User-Agent":
            "15!@ejh46)(*%#!@s4h68a4rgsagH&^%%$#@!JKFKVYRDTgjsgakjzghfjJ%$#@#%HFYD32434",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "Review posted successfully") {
          Swal.fire({
            position: "top",
            icon: "success",
            title: "Thanks For Your Feedback!",
            text: "Your feedback will help us to improve our services",
            showConfirmButton: false,
            timer: 3000,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          position: "top",
          icon: "error",
          title: "Oops...",
          text: "Something Wrong.. Try later",
          showConfirmButton: false,
          timer: 3000,
        });
      });

    closeModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="text-center py-2 px-5">
        <form onSubmit={handleSubmit} className="review_form">
          <h2 className="pb-3">Feedback Form</h2>
          <Rating
            transition
            onClick={handleRating}
            // ratingValue={ratingValue}
            fillColor="#bd0451"
            allowHalfIcon
          />
          <p className="m-0 pb-1 text-left pt-3 font-weight-bold">
            Description:
          </p>
          <textarea
            type="text"
            rows="3"
            required
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="d-flex justify-content-between pt-3">
            <button type="submit">সেভ করুন</button>
            <button onClick={closeModal}>স্কীপ করুন</button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default ReviewModal;
