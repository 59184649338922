import React, { useEffect, useState } from "react";
import logo from "../../../Assets/final-logo.png";
import registerImg from "../../../Media/Group-input.png";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import { login } from "../../../Redux/Actions/userActions";
import OtpLoader from "../OtpLoader";
import Footer from "../../Footer/Footer";
import "../login.css";

const NewLogin = () => {
  const history = useHistory();

  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  const [numberdisplay, setNumberdisplay] = useState("block");
  const [otpdisplay, setOtpdisplay] = useState("none");
  const [message, setMessage] = useState(
    "The number is not valid number and must use (+88) country code"
  );
  const [number, setNumber] = useState("+88");
  const [otp, setOtp] = useState("");
  const [messageshow, setMessageshow] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [afterOtpLoading, setAfterOtpLoading] = useState(false);
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const swalFire = () => {
    Swal.fire({
      position: "top",
      icon: "error",
      title: "Oops...",
      text: "You are not registered yet!",
      showConfirmButton: false,
      timer: 2000,
    });
  };

  const onSignInSubmit = async (e) => {
    e.preventDefault();
    setOtpLoading(true);
    setMessageshow(false);

    if (number.trim().length !== 14) {
      setOtpLoading(false);
      return setMessageshow(true);
    }

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user/login/?version=otp`,
        { Phone: number.trim() },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.message && response?.data?.otp_status === "sent") {
        // console.log("response.data.message = ", response.data.message);
        // console.log("response.data.otp_status = ", response.data.otp_status);
        setOtpLoading(false);
        //console.log("OTP has been sent");
        setNumberdisplay("none");
        setOtpdisplay("block");
      } else {
        setOtpLoading(false);
        alert("Otp is not send for uncertain reason.Try again later");
      }

      // console.log("response = ", response);
    } catch (error) {
      if (error?.response?.data?.message) {
        // console.log(error.response.data.message);
        if (error.response.data.message === "User not found") {
          setOtpLoading(false);
          swalFire();
        } else {
          alert("SomeThing Went Wrong");
        }
      } else {
        alert("SomeThing Went Wrong");
      }
    }
  };

  const onSubmitOTP = async (e) => {
    e.preventDefault();
    setAfterOtpLoading(true);
    const code = otp;
    //console.log(code);

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/user/otp_login/`,
        { Phone: number.trim(), otp: code.trim() },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      localStorage.setItem("success", ""); //don't know, just paste from previous code

      // console.log("response = ", response);
      // console.log("response.data.access = ", response.data.access);
      // console.log("response.data.refresh = ", response.data.refresh);
      localStorage.setItem("access_token", response.data.access);
      localStorage.setItem("refresh_token", response.data.refresh);

      // copy paste below codes

      localStorage.setItem("registerd", "done");
      if (localStorage.getItem("url")) {
        history.push(localStorage.getItem("url"));
      } else {
        history.replace(from);
      }
    } catch (error) {
      setAfterOtpLoading(false);
      // console.log("error = ", error);

      Swal.fire({
        position: "top",
        icon: "error",
        title: "Oops...",
        text: "Incorrect OTP, Please Provide Valid OTP",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  return (
    <section>
      <div className="container">
        <div className="text-center p-4">
          <img
            onClick={() => history.push("/")}
            style={{ cursor: "pointer", height: "80px" }}
            className="img-fluid"
            src={logo}
            alt="..."
          ></img>
        </div>
        <div className="row justify-content-between pt-3 pb-3 mt-2 mb-2">
          <div className="col-md-6 text-center text-md-left">
            <h1 style={{ color: "#bd095a" }}>স্বাগতম,</h1>

            {numberdisplay === "block" ? (
              <h4 style={{ fontFamily: "kalpurush", lineHeight: "150%" }}>
                অনুগ্রহ করে আপনার ফোন নাম্বার টি ভেরিফাই করে রেজিস্ট্রেশন
                সম্পূর্ণ করুন........
              </h4>
            ) : (
              <h4 style={{ fontFamily: "kalpurush", lineHeight: "150%" }}>
                এস এম এস এ প্রদত্ত OTP টি প্রদান করুন.........
              </h4>
            )}
            <img className="img-fluid" src={registerImg} alt="register img" />
          </div>
          <div className="col-md-1 p-0 border_left"></div>
          <div className="col-md-5 align-self-center text-center mt-4 pt-5 mt-md-0 pt-md-0">
            <form
              onSubmit={onSignInSubmit}
              style={{ display: `${numberdisplay}` }}
            >
              <h4>মোবাইল নাম্বার:</h4>
              <div id="sign-in-button"></div>
              <input
                className="mobile_field"
                style={{ opacity: "55%" }}
                type="text"
                name="mobile"
                required
                value={number}
                onChange={(e) => {
                  setNumber(e.target.value);
                }}
              />

              {otpLoading ? <OtpLoader></OtpLoader> : <h4></h4>}
              <div id="recaptcha-container"></div>
              <button className="mt-3 registerButton" type="submit">
                ভেরিফাই করুন
              </button>
            </form>
            <br></br>

            <form onSubmit={onSubmitOTP} style={{ display: `${otpdisplay}` }}>
              <h4>OTP</h4>
              <input
                className="mobile_field"
                style={{ opacity: "55%" }}
                type="text"
                name="otp"
                required
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
              <br />
              {afterOtpLoading ? <OtpLoader /> : <h4></h4>}
              <button className="mt-3 registerButton" type="submit">
                যাচাই করুন
              </button>
            </form>
            <br></br>

            {error === "User not found" ? <>{swalFire()}</> : <span></span>}

            <p>
              এখনো রেজিস্টার না করে থাকলে{" "}
              <a href="/register" style={{ textDecoration: "none" }}>
                রেজিস্টার
              </a>{" "}
              করুন
            </p>

            {messageshow ? (
              <p
                style={{
                  color: "#CC0A61",
                  textAlign: "left",
                  paddingRight: "23%",
                }}
              >
                {message}
              </p>
            ) : (
              <p></p>
            )}
            <label></label>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </section>
  );
};

export default NewLogin;
