import React from "react";
import { Modal } from "react-bootstrap";
import lalpathlabs from "../../Media/dr-lal-01.png";
import pravaHealth from "../../Media/Praavahealth.jpg";
import unitedHospital from "../../Media/download.jpg";
import ibnSina from "../../Media/IBN-SINA-LOGO_180x180.jpg";
import popularMedical from "../../Media/popular_bvvh0a.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const TestDetailsModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>
          Test Name : <span>{props.name}</span>
        </Modal.Title>
        <button className="border-0 bg-white" onClick={props.handleClose}>
          <FontAwesomeIcon
            icon={faClose}
            className="fa-2x"
            style={{ color: "#C70A5F" }}
          ></FontAwesomeIcon>
        </button>
      </Modal.Header>
      <Modal.Body className="">
        {/* <div className="row pb-3" style={{ borderBottom: "1px dotted" }}>
          <div className="col-2">
            <img src={lalpathlabs} alt="lalpathlabs" height={70} />
          </div>
          <div className="col-6 mt-4">
            <h5 className="">Dr. Lal Pathlabs</h5>
          </div>
          <div
            className="col-4"
            style={{ paddingLeft: "2rem", fontWeight: "600" }}
          >
            {props.lalPathlabsTestData?.Missing_test.length !== 0 ? (
              <span>
                <p className="mt-3" style={{ color: "#C70A5F" }}>
                  Test Not Available
                </p>
              </span>
            ) : (
              <>
                <span className="row">
                  Test Price: {props.lalPathlabsTestData?.Sub_Total}
                </span>
                <span className="row">
                  Discount: - {props.lalPathlabsTestData?.Discount}%
                </span>
                <span className="row">
                  Total Price:{" "}
                  {props.lalPathlabsTestData?.Sub_Total_After_Discount}
                </span>
              </>
            )}
          </div>
        </div> */}
        <div className="row pb-3">
          <div className="col-2">
            <img src={pravaHealth} alt="lalpathlabs" height={70} />
          </div>
          <div className="col-6 mt-4">
            <h5 className="">Praava Health</h5>
          </div>
          <div
            className="col-4"
            style={{ paddingLeft: "2rem", fontWeight: "600" }}
          >
            {props.pravehealthTestData?.Missing_test.length !== 0 ? (
              <span>
                <p className="mt-3" style={{ color: "#C70A5F" }}>
                  Test Not Available
                </p>
              </span>
            ) : (
              <>
                <span className="row">
                  Test Price: {props.pravehealthTestData?.Sub_Total}
                </span>
                <span className="row">
                  Discount: -{props.pravehealthTestData?.Discount}%
                </span>
                <span className="row">
                  Total Price:{" "}
                  {props.pravehealthTestData?.Sub_Total_After_Discount}
                </span>
              </>
            )}
          </div>
        </div>
        {/* <div className="row pt-3 pb-3" style={{ borderBottom: "1px dotted" }}>
          <div className="col-2">
            <img src={unitedHospital} alt="unitedHospital" height={70} />
          </div>
          <div className="col-6 mt-4">
            <h5 className="">United Hospital</h5>
          </div>
          <div
            className="col-4"
            style={{ paddingLeft: "2rem", fontWeight: "600" }}
          >
            {props.unitedTestData?.Missing_test.length !== 0 ? (
              <span>
                <p className="mt-3" style={{ color: "#C70A5F" }}>
                  Test Not Available
                </p>
              </span>
            ) : (
              <>
                <span className="row">
                  Test Price: {props.unitedTestData?.Sub_Total}
                </span>
                <span className="row">
                  Discount: - {props.unitedTestData?.Discount}%
                </span>
                <span className="row">
                  Total Price: {props.unitedTestData?.Sub_Total_After_Discount}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="row pt-3 pb-3" style={{ borderBottom: "1px dotted" }}>
          <div className="col-2">
            <img src={ibnSina} alt="ibnSina" height={70} />
          </div>
          <div className="col-6 mt-4">
            <h5 className="">Ibn Sina</h5>
          </div>
          <div
            className="col-4"
            style={{ paddingLeft: "2rem", fontWeight: "600" }}
          >
            {props.ibnSinaTestData?.Missing_test.length !== 0 ? (
              <span>
                <p className="mt-3" style={{ color: "#C70A5F" }}>
                  Test Not Available
                </p>
              </span>
            ) : (
              <>
                <span className="row">
                  Test Price: {props.ibnSinaTestData?.Sub_Total}
                </span>
                <span className="row">
                  Discount: - {props.ibnSinaTestData?.Discount}%
                </span>
                <span className="row">
                  Total Price: {props.ibnSinaTestData?.Sub_Total_After_Discount}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="row pt-3 pb-3">
          <div className="col-2">
            <img src={popularMedical} alt="popularMedical" height={70} />
          </div>
          <div className="col-6 mt-4">
            <h5 className="">Popular</h5>
          </div>
          <div
            className="col-4"
            style={{ paddingLeft: "2rem", fontWeight: "600" }}
          >
            {props.popularTestData?.Missing_test.length !== 0 ? (
              <span>
                <p className="mt-3" style={{ color: "#C70A5F" }}>
                  Test Not Available
                </p>
              </span>
            ) : (
              <>
                <span className="row">
                  Test Price: {props.popularTestData?.Sub_Total}
                </span>
                <span className="row">
                  Discount: - {props.popularTestData?.Discount}%
                </span>
                <span className="row">
                  Total Price: {props.popularTestData?.Sub_Total_After_Discount}
                </span>
              </>
            )}
          </div>
        </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default TestDetailsModal;
