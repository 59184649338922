import React, { useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../Media/logo.png";
import Footer from "../Footer/Footer";
import "./FooterContent.css";
import "aos/dist/aos.css";
import Aos from "aos";

const ReturnPolicy = () => {
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <div>
      <Navbar className="footer_navbar">
        <Navbar.Brand style={{ margin: "0 0 0 0" }}>
          <img src={logo} onClick={() => history.push("/")} alt="logo" fluid />
        </Navbar.Brand>
      </Navbar>
      <div className="about_us user-select-none">
        <h2 data-aos="fade-up">Return Policy</h2>
        <p data-aos="fade-up">
          If there are any problems in our website or app, then don’t worry. We
          are here to help.
        </p>
        <br />
        <h4 data-aos="fade-up">Pathology Services:</h4>
        <p data-aos="fade-up">
          When a customer books a pathology test, our diagnostic centres will be
          notified. A customer will be able to provide the sample within 24
          hours. This means the medical assistants will be in the patient’s
          house within 24 hours. Moreover, they will get the hard copy of the
          test report within 48 hours of providing the sample to the medical
          assistants.
          <br />
          <br />
          <p data-aos="fade-up" id="footP">
            When a customer books a test, the medical assistants will at first
            call the customer. The call is given to ask for a suitable time from
            the patients. After agreeing on a suitable time, a slot will be
            given. If a customer wants to cancel an appointment, then he/she
            needs to let us/medical assistant know at least 3 hours beforehand.
            For example, if the slot is at 11:00AM. Then the customer needs to
            cancel within 8AM. Any cancellations after that will not be
            entertained. So, a customer will be able to get the full refund if
            he/she follows these rules and regulations.
          </p>
          <br />
          <br />
          <p data-aos="fade-up">
            If a customer is following the rules and regulations and then asking
            for a refund, then our team will look into the matter. And, if
            everything is okay then a customer will be able to avail the refund
            by 10 business days. The money will be delivered to a customer’s
            Bank account or Bkash account.
          </p>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default ReturnPolicy;
