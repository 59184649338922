import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
// import logo from "../../Media/logo.png";
import logo from "../../Media/logo2.png";
import "../Header/header.css";
import Search from "./Search";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, logout } from "../../Redux/Actions/userActions";
import newLogo from "../../Assets/final-logo.png";

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const history = useHistory();
  const handleScroll = () => {
    if (window.scrollY > 120) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", handleScroll);

  // const navbarClose = () => {
  //   let navBar = document.querySelector(".navbar-collapse.collapse");
  //   navBar.classList.remove("show");
  // };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user } = userDetails;

  if (user) {
    var userName = user.Name;
  }

  const handleLogout = () => {
    dispatch(logout());
    // history.push("/");
  };

  return (
    <section>
      <nav className="navbar navbar-expand-lg navbar-light pb-3 mb-2">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img style={{ width: "10rem" }} src={newLogo} alt="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FontAwesomeIcon icon={faBarsStaggered} />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {/* <li class="nav-item">
                <a class="nav-link" href="#"></a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#"></a>
              </li>

              <li class="nav-item">
                <a class="nav-link disabled"></a>
              </li> */}
            </ul>
            <span className="mt-3">
              <Search></Search>
            </span>
            <span className="navbar-text mt-5 mt-md-4 mt-lg-3">
              {localStorage.getItem("refresh_token") &&
              localStorage.getItem("access_token") ? (
                <div className="dropdown ">
                  <button
                    className="dropdown_btn dropdown-toggle shadow"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userName}
                  </button>
                  <div
                    className="dropdown-menu login_dropdown_menu shadow"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      className="dropdown-item"
                      onClick={() => history.push("/dashboard")}
                    >
                      Dashboard
                    </a>
                    <a
                      className="dropdown-item"
                      onClick={() => history.push("/selectedtests")}
                    >
                      Cart Items
                    </a>
                    <a
                      style={{
                        color: "#bd0451",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                      href="/"
                      onClick={handleLogout}
                    >
                      Logout
                    </a>
                  </div>
                </div>
              ) : (
                <button
                  className="login_btn"
                  onClick={() => history.push("/login")}
                >
                  Login or Register
                </button>
              )}
            </span>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Header;
