import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

// // Your web app's Firebase configuration
// const config = {
//   apiKey: "AIzaSyB-38427JNmhEQl6X2q_PueeN4dKMftNU0",
//   authDomain: "carebox-pathology.firebaseapp.com",
//   projectId: "carebox-pathology",
//   storageBucket: "carebox-pathology.appspot.com",
//   messagingSenderId: "785310664065",
//   appId: "1:785310664065:web:b013cfb6f7ec89e03e5480",
// };

// carebox config
const config = {
  apiKey: "AIzaSyBDwMF7UJ0mQuGvfZ-2as96Nr49qZX_As4",
  authDomain: "care-box-front.firebaseapp.com",
  projectId: "care-box-front",
  storageBucket: "care-box-front.appspot.com",
  messagingSenderId: "637956017561",
  appId: "1:637956017561:web:c143d2c39c973fd4125708",
  measurementId: "G-HMLVJQ27WK",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(config);
  // firebase.initializeApp({});
} else {
  firebase.app(); // if already initialized, use that one
}

export default firebase;
